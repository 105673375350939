import React, { useState, useContext } from 'react';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

import { Button } from '../index';

import { AppContext } from '../../store/AppContext';

import closeIcon from '../../../static/icons/close.svg';

import styles from './OrderCallModal.module.scss';

const PhoneNumberMaskInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

const OrderCallModal = () => {
  const [firstName, setFirstName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);

  const { store, setStore } = useContext(AppContext);

  const handleOnClose = () => setStore('isShowOrderCallModal', false);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://formspree.io/mgengaqw', {
      method: 'POST',
      body: JSON.stringify({
        firstName,
        phoneNumber,
      }),
    })
      .finally(() => {
        handleOnClose();
        setIsOpenSnackBar(true);
        firebase.analytics().logEvent('call_now_form_action');
      });
  };

  const isDisabled = firstName.length < 4 || phoneNumber.replace(/[\s-_\(\)]/g, '').length < 8;

  const { contentfulContacts, contentfulOrderCallWindow } = useStaticQuery(graphql`
    {
      contentfulContacts {
        phone
      }
      contentfulOrderCallWindow {
        title
        subtitle
        successMessage
      }
    }
  `);

  return (
    <div>
      <div className={clsx(styles.overlay, {
        [styles.hide]: !store.isShowOrderCallModal,
      })}
      >
        <div className={styles.modal}>
          <button onClick={handleOnClose} className={styles.closeButton} type="button">
            <img src={closeIcon} alt="close" />
          </button>
          <h2 className={styles.title}>{contentfulOrderCallWindow.title}</h2>
          <p className={styles.text}>{contentfulOrderCallWindow.subtitle}</p>
          <p className={styles.text}>
            {'Or you can call us at '}
            <a
              className={styles.phone}
              href={`tel:${contentfulContacts.phone}`}
              onClick={() => {
                firebase.analytics().logEvent('click_phone_call_now');
              }}
            >
              {contentfulContacts.phone}

            </a>
          </p>

          <form className={styles.form}>
            <div className={styles.field}>
              <FormControl fullWidth>
                <InputLabel htmlFor="firstName">FIRST NAME</InputLabel>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  name="firstName"
                />
              </FormControl>
            </div>

            <div className={styles.field}>
              <FormControl fullWidth>
                <InputLabel htmlFor="phoneNumber">PHONE NUMBER</InputLabel>
                <Input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  name="phoneNumber"
                  inputComponent={PhoneNumberMaskInput}
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              label="Contact With Us"
              onClick={handleSubmit}
              isDisabled={isDisabled}
            />
          </form>
        </div>
      </div>

      <Snackbar
        open={isOpenSnackBar}
        autoHideDuration={5000}
        onClose={() => setIsOpenSnackBar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setIsOpenSnackBar(false)}
          severity="success"
        >
          {contentfulOrderCallWindow.successMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OrderCallModal;
