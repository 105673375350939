import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

import { Button, Address } from '../index';

import { AppContext } from '../../store/AppContext';

import styles from './Footer.module.scss';

const Footer = () => {
  const { setStore } = useContext(AppContext);

  const handleOrderCallButton = () => {
    setStore('isShowOrderCallModal', true);
    firebase.analytics().logEvent('open_call_now_footer');
  };

  const { contentfulFooter } = useStaticQuery(graphql`
    {
      contentfulFooter {
        title
        subtitle
        copyright
      }
    }
  `);

  return (
    <>
      <footer className={styles.footer}>
        <div className="container">
          <h2 className={styles.title}>{contentfulFooter.title}</h2>
          <p className={styles.description}>{contentfulFooter.subtitle}</p>
          <address className={styles.address}>
            <Address />
          </address>
          <Button onClick={handleOrderCallButton} label="Request Call Back" theme="secondary" />
        </div>
      </footer>
      <div className={styles.copyright}>
        <div className="container">
          <span className={styles.copyrightText}>{contentfulFooter.copyright}</span>
        </div>
      </div>
    </>
  );
};

export default Footer;
