const menu = {
  HOME: {
    label: 'Home',
    path: '/',
  },
  CARPET: {
    label: 'Carpet',
    path: '/carpet',
  },
  JANITORIAL: {
    label: 'Janitorial',
    path: '/janitorial',
  },
  BLINDS: {
    label: 'Blinds',
    path: '/blinds',
  },
  FLOOR: {
    label: 'Floor',
    path: '/floor',
  },
  GALLERY: {
    label: 'Gallery',
    path: '/gallery',
  },
};

export default menu;
