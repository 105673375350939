import React from 'react';

import IconCheck from '../../../static/icons/check.svg';
import IconCross from '../../../static/icons/cross.svg';

import styles from './EffectsList.module.scss';

const EffectsList = ({ data, title }) => (
  <div className={styles.list}>
    {!!title && <h4 className={styles.title}>{title}</h4>}
    {data.map(({ description, isChecked }) => (
      <div className={styles.item} key={description}>
        <img
          className={styles.icon}
          src={isChecked ? IconCheck : IconCross}
          alt={isChecked ? 'contains' : 'does not contain'}
        />
        <span className={styles.description}>{description}</span>
      </div>
    ))}
  </div>
);

export default EffectsList;
