import React, { useContext } from 'react';
import { Link } from 'gatsby';
import useWindowSize from 'react-use/lib/useWindowSize';

import { AppContext } from '../../store/AppContext';

import { CSSBreakpoints } from '../../constants';

import menu from '../../data/menu';

import styles from './MobileMenu.module.scss';

const MobileMenu = () => {
  const { store, setStore } = useContext(AppContext);

  const { width } = useWindowSize();

  const handleOnClickLink = () => {
    setStore('isShowMobileMenu', false);
  };

  if (!store.isShowMobileMenu || width > CSSBreakpoints.md) {
    return null;
  }

  return (
    <div className={styles.mobileMenu}>
      <ul className={styles.menuList}>
        {Object.values(menu).map((item) => (
          <li key={item.label} className={styles.menuListItem}>
            <Link onClick={handleOnClickLink} activeClassName={styles.activeLink} to={item.path}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileMenu;
