import React, { useContext } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import useWindowSize from 'react-use/lib/useWindowSize';
import clsx from 'clsx';
import firebase from 'gatsby-plugin-firebase';

import { Button } from '../index';

import { AppContext } from '../../store/AppContext';

import Logo from '../../../static/logo.png';

import { CSSBreakpoints } from '../../constants';

import menu from '../../data/menu';

import styles from './Header.module.scss';

const BarsIcon = () => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="4" rx="2" fill="url(#paint0_linear)" />
    <rect y="8" width="24" height="4" rx="2" fill="url(#paint1_linear)" />
    <rect y="16" width="24" height="4" rx="2" fill="url(#paint2_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="1.3411e-07" y1="2" x2="23.035" y2="2" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2CADE4" />
        <stop offset="1" stopColor="#6912D9" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="1.3411e-07" y1="10" x2="23.035" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2CADE4" />
        <stop offset="1" stopColor="#6912D9" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="1.3411e-07" y1="18" x2="23.035" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2CADE4" />
        <stop offset="1" stopColor="#6912D9" />
      </linearGradient>
    </defs>
  </svg>
);

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.585786 20.5858C-0.195262 21.3668 -0.195262 22.6332 0.585787 23.4142C1.36684 24.1953 2.63317 24.1953 3.41421 23.4142L12 14.8284L20.5858 23.4142C21.3668 24.1953 22.6332 24.1953 23.4142 23.4142C24.1953 22.6332 24.1953 21.3668 23.4142 20.5858L14.8284 12L23.4142 3.41421C24.1953 2.63317 24.1953 1.36683 23.4142 0.585787C22.6332 -0.195262 21.3668 -0.195262 20.5858 0.585787L12 9.17157L3.41421 0.585788C2.63316 -0.19526 1.36683 -0.19526 0.585787 0.585788C-0.195262 1.36684 -0.195262 2.63317 0.585787 3.41422L9.17157 12L0.585786 20.5858Z" fill="url(#paint0_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="1.3411e-07" y1="12" x2="23.035" y2="12" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2CADE4" />
        <stop offset="1" stopColor="#6912D9" />
      </linearGradient>
    </defs>
  </svg>
);

const Header = () => {
  const { store, setStore } = useContext(AppContext);

  const { contentfulContacts } = useStaticQuery(graphql`
    {
      contentfulContacts {
        phone
      }
    }
  `);

  const { y } = useWindowScroll();
  const { width } = useWindowSize();

  const handleOrderCallButton = () => {
    window.open(`tel:${contentfulContacts.phone}`, '_self');
    firebase.analytics().logEvent('call_now_header');
  };

  const handleMobileMenuButton = () => {
    if (store.isShowMobileMenu) {
      setStore('isShowMobileMenu', false);
    } else {
      setStore('isShowMobileMenu', true);
    }
  };

  const isSmallDevice = width < CSSBreakpoints.md;

  const headerStyles = clsx(
    styles.header,
    y > 300 && !store.isShowMobileMenu && styles.headerFloat,
    store.isShowMobileMenu && styles.headerInMobileMenu,
  );

  return (
    <header className={headerStyles}>
      <div className="container">
        <div className={styles.headerContent}>
          {!store.isShowMobileMenu && (
            <Link className={styles.logoLink} to="/">
              <img
                className={styles.logoImage}
                src={Logo}
                alt="White Glove Cleaning"
              />
            </Link>
          )}
          {isSmallDevice
            ? (
              <button
                type="button"
                aria-label="open mobile menu"
                onClick={handleMobileMenuButton}
                className={styles.barsIcon}
              >
                {store.isShowMobileMenu ? <CloseIcon /> : <BarsIcon />}
              </button>
            ) : (
              <>
                <ul className={styles.menuList}>
                  {Object.values(menu).map((item) => (
                    <li key={item.label} className={styles.menuListItem}>
                      <Link activeClassName={styles.activeLink} to={item.path}>
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className={styles.rightWrapper}>
                  <Button onClick={handleOrderCallButton} label="Call now" theme="secondary" />
                </div>
              </>
            )}
        </div>
      </div>
    </header>
  );
};

export default Header;
