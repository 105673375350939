import React, { useReducer, createContext } from 'react';

const initialState = {
  isShowOrderCallModal: false,
  isShowMobileMenu: false,
};

const AppContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STORE':
      return {
        ...state,
        [action.store.prop]: action.store.value,
      };

    default:
      return state;
  }
};

const AppContextProvider = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);

  const setStore = (prop, value) => {
    dispatch({
      type: 'SET_STORE',
      store: {
        prop,
        value,
      },
    });
  };

  return (
    <AppContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
