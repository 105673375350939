import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fab from '@material-ui/core/Fab';
import { FaPhone } from 'react-icons/fa';
import firebase from 'gatsby-plugin-firebase';

import '../styles/reset.scss';
import '../styles/global.scss';

import {
  Header, Footer, OrderCallModal, MobileMenu, SEO,
} from '../components';

import { AppContextProvider } from '../store/AppContext';

import styles from './index.module.scss';

const Layout = ({ children }) => {
  const { contentfulContacts } = useStaticQuery(graphql`
    {
      contentfulContacts {
        phone
      }
    }
  `);

  const handleFBA = () => {
    window.open(`tel:${contentfulContacts.phone}`, '_self');
    firebase.analytics().logEvent('fab-phone');
  };

  return (
    <AppContextProvider>
      <SEO />
      <div>
        <Header />
        {children}
        <Footer />

        <OrderCallModal />

        <MobileMenu />

        <div className={styles.fab}>
          <Fab onClick={handleFBA} color="primary" aria-label="call">
            <FaPhone size={32} />
          </Fab>
        </div>
      </div>
    </AppContextProvider>
  );
};

export default Layout;
