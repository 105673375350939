import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useStaticQuery, graphql } from 'gatsby';

import styles from './JanitorialModal.module.scss';

const closeIcon = (
  <svg className={styles.closeButtonIcon} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L15 15L28 28M2 28L28 2" stroke="#333333" strokeWidth="4" strokeLinecap="round" />
  </svg>
);

const JanitorialModal = ({ open, onClose }) => {
  const { contentfulJanitorialPage } = useStaticQuery(graphql`
  {
    contentfulJanitorialPage {
      pricesTitle
      pricesList
    }
  }
`);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        center
        classNames={{
          modal: styles.modal,
          overlay: styles.overlay,
        }}
        closeIcon={closeIcon}
      >
        <h2 className={styles.title}>{contentfulJanitorialPage.pricesTitle}</h2>
        {contentfulJanitorialPage.pricesList.map((item) => (
          <p className={styles.text}>{item}</p>
        ))}
      </Modal>
    </div>
  );
};

export default JanitorialModal;
