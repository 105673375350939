import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import SlidePaginationButton from '../../../static/icons/slider-button.png';

import styles from './Slider.module.scss';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const Slide = ({ children }) => (
  <div className={styles.slide}>
    {children}
  </div>
);

const ArrowButton = ({ isPrevious = false, onClick }) => {
  const altText = isPrevious ? 'Previous' : 'Next';
  const buttonClassName = isPrevious ? styles.previousButton : styles.nextButton;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${styles.arrowButton} ${buttonClassName}`}
    >
      <img
        className={isPrevious ? styles.previousButtonImage : ''}
        src={SlidePaginationButton}
        alt={altText}
      />
    </button>
  );
};

const Pagination = (index) => (
  <div className={styles.paginationDot}>
    {index + 1}
  </div>
);

const Slider = ({ children }) => (
  <SlickSlider
    {...settings}
    dotsClass={styles.paginationWrapper}
    customPaging={Pagination}
    nextArrow={<ArrowButton />}
    prevArrow={<ArrowButton isPrevious />}
  >
    {children}
  </SlickSlider>
);

export default Slider;
