import React from 'react';

import { EffectsList } from '../index';

import IconTriangle from '../../../static/icons/markers/triangle.png';
import IconSquare from '../../../static/icons/markers/square.png';
import IconDiamond from '../../../static/icons/markers/diamond.png';
import IconCircle from '../../../static/icons/markers/circle.png';

import styles from './ServicesList.module.scss';

const markers = [IconTriangle, IconSquare, IconDiamond, IconCircle];

const ServicesList = ({ data }) => (
  <ul className={styles.list}>
    {data.map(({
      name, description, price, effects,
    }, index) => (
      <li key={name} className={styles.item}>
        <h3 className={styles.title}>{name}</h3>
        <p className={styles.description}>{description.description || description}</p>
        {!!effects && <EffectsList data={effects} title="We offer cleaning in the Ultrasonic Machine. " />}
        {!!price && <p className={styles.price}>{price}</p>}
        <img className={styles.marker} src={markers[index]} alt="list marker" />
      </li>
    ))}
  </ul>
);

export default ServicesList;
