import React from 'react';
import clsx from 'clsx';

import IconArrow from '../../../static/icons/arrow.svg';

import styles from './Button.module.scss';

const Button = ({
  onClick,
  type = 'button',
  label = '',
  theme = 'primary',
  isShowArrow = false,
  isDisabled = false,
  buttonClassName = '',
}) => {
  const themeClassName = {
    primary: styles.primary,
    secondary: styles.secondary,
  }[theme];

  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(themeClassName, { [styles.disabled]: isDisabled }, buttonClassName)}
      disabled={isDisabled}
    >
      {label}
      {isShowArrow && <img className={styles.iconArrow} src={IconArrow} alt="arrow right" />}
    </button>
  );
};

export default Button;
