import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import {
  FaGoogle, FaFacebook, FaYelp, FaInstagram,
} from 'react-icons/fa';

import styles from './Address.module.scss';

const Address = () => {
  const { contentfulContacts } = useStaticQuery(graphql`
    {
      contentfulContacts {
        phone
        email
        yelp
      }
    }
  `);

  return (
    (
      <ul className={styles.addressList}>
        <li className={`${styles.addressPhone} ${styles.addressListItem}`}>
          <p className={styles.addressLabel}>Phone number:</p>
          <a
            className={styles.addressLink}
            href={`tel:${contentfulContacts.phone}`}
            onClick={() => {
              firebase.analytics().logEvent('click_phone_footer');
            }}
          >
            {contentfulContacts.phone}
          </a>
        </li>
        <li className={`${styles.addressEmail} ${styles.addressListItem}`}>
          <p className={styles.addressLabel}>Email:</p>
          <a
            className={styles.addressLink}
            href={`mailto:${contentfulContacts.email}`}
            onClick={() => {
              firebase.analytics().logEvent('click_email_footer');
            }}
          >
            {contentfulContacts.email}

          </a>
        </li>
        <li className={`${styles.addressSocial} ${styles.addressListItem}`}>
          <p className={styles.addressLabel}>Social messengers:</p>
          <ul className={styles.socialList}>
            <li className={styles.socialListItem}>
              <a
                href="https://www.shorturl.at/agMN8"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  firebase.analytics().logEvent('click_google_footer');
                }}
              >
                <FaGoogle size={32} />
              </a>
            </li>
            <li className={styles.socialListItem}>
              <a
                href="https://www.facebook.com/White-Glove-Cleaning-124049557682781"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  firebase.analytics().logEvent('click_facebook_footer');
                }}
              >
                <FaFacebook size={32} />
              </a>
            </li>
            <li className={styles.socialListItem}>
              <a
                href={contentfulContacts.yelp}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  firebase.analytics().logEvent('click_yelp_footer');
                }}
              >
                <FaYelp size={32} />
              </a>
            </li>
            <li className={styles.socialListItem}>
              <a
                href="https://www.instagram.com/nataliyakropyvska/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  firebase.analytics().logEvent('click_instagram_footer');
                }}
              >
                <FaInstagram size={32} />
              </a>
            </li>
          </ul>
        </li>
      </ul>
    )
  );
};

export default Address;
