import React, { useContext } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Formik, FieldArray } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

import { Button } from '../index';

import { AppContext } from '../../store/AppContext';

import styles from './CarpetCalculatorModal.module.scss';

const closeIcon = (
  <svg className={styles.closeButtonIcon} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L15 15L28 28M2 28L28 2" stroke="#333333" strokeWidth="4" strokeLinecap="round" />
  </svg>
);

const CarpetCalculatorModal = ({ open, onClose }) => {
  const { contentfulContacts, contentfulCarpetPage } = useStaticQuery(graphql`
    {
      contentfulContacts {
        phone
      }
      contentfulCarpetPage {
        calculatorTitle
        calculatorDescription
        calculatorCall
      }
    }
  `);

  const { setStore } = useContext(AppContext);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        center
        classNames={{
          modal: styles.modal,
          overlay: styles.overlay,
        }}
        closeIcon={closeIcon}
      >
        <h2 className={styles.title}>{contentfulCarpetPage.calculatorTitle}</h2>
        <p className={styles.text}>{contentfulCarpetPage.calculatorDescription}</p>
        <p className={styles.text}>
          {contentfulCarpetPage.calculatorCall}
          {' '}
          <a
            className={styles.phone}
            href={`tel:${contentfulContacts.phone}`}
            onClick={() => {
              firebase.analytics().logEvent('click_phone_carpet_calculator');
            }}
          >
            {contentfulContacts.phone}

          </a>
        </p>

        <Formik
          onSubmit={(values) => {
            onClose();
            setStore('isShowOrderCallModal', true);
            firebase.analytics().logEvent('open_call_now_carpet_calculator');
            firebase.analytics().logEvent('carpet_calculator', {
              total_price: (values.plans.reduce(
                (acc, current) => acc + current.size * current.cost, 0,
              ) * 0.01).toFixed(2),
            });
          }}
          initialValues={{ plans: [{ cost: 40, size: '' }] }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
          }) => (

            <form className={styles.form}>

              <FieldArray
                name="plans"
                render={(arrayHelpers) => (
                  <>
                    {values.plans.map((plan, index) => (
                      <div key={index} className={styles.field}>
                        <FormControl className={styles.select}>
                          <InputLabel>OPTION</InputLabel>
                          <Select
                            name={`plans[${index}].cost`}
                            onChange={handleChange}
                            value={values.plans[index].cost}
                          >
                            <MenuItem value={40}>BASIC</MenuItem>
                            <MenuItem value={55}>STANDARD</MenuItem>
                            <MenuItem value={65}>DELUXE</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl className={styles.size}>
                          <InputLabel>SIZE</InputLabel>
                          <Input
                            name={`plans[${index}].size`}
                            onChange={handleChange}
                            type="number"
                            onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                          />
                        </FormControl>

                        <span className={styles.measure}>sq/ft</span>

                        {values.plans.length > 1 && (
                        <button type="button" onClick={() => arrayHelpers.remove(index)} className={styles.removeFieldButton}>
                          {closeIcon}
                        </button>
                        )}
                      </div>
                    ))}

                    {values.plans.length < 3 && (
                    <button
                      type="button"
                      className={styles.add}
                      onClick={() => {
                        arrayHelpers.push({ cost: 40, size: '' });
                      }}
                    >
                      Add cleaning +
                    </button>
                    )}
                  </>
                )}
              />

              <footer className={styles.footer}>
                <Button onClick={handleSubmit} type="submit" label="Request Call Back" theme="secondary" buttonClassName={styles.button} />
                <div>
                  Total cleaning price:
                  {' '}
                  {(values.plans.reduce((acc, current) => acc + current.size * current.cost, 0) * 0.01).toFixed(2) || '____'}
                  $
                </div>
              </footer>
            </form>
          )}
        </Formik>

      </Modal>
    </div>
  );
};

export default CarpetCalculatorModal;
